import React from "react"

import DefaultLayout from "../layout/default.layout"
import AboutContainer from "../containers/about/about.container"
import LandingContainer from '../containers/landing/landing.container'
import ProdutosHomeContainer from "../containers/produtos-home/produtos.container"

import { Helmet } from 'react-helmet';

const IndexPage = () => (
  <DefaultLayout>
    <Helmet>
      <title>Horus | Gerenciamento de Barragens</title>
    </Helmet>
    <LandingContainer></LandingContainer>
    <AboutContainer></AboutContainer>
    <ProdutosHomeContainer></ProdutosHomeContainer>
  </DefaultLayout>
)

export default IndexPage
