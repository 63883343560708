import React from 'react'
import './landing.container.style.scss'
import imgonda from "../../images/ligaonda.svg"
export default class LandingContainer extends React.Component {
    render() {
        return (
            <div className="bolhas">
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="bolha"></div>
                <div className="nomeempresa">H O R U S</div>
                <p className="subtituloempresa">Sistema de monitoramento e gestão de segurança de barragens</p>

                <svg className="teste"  viewBox="0 0 1507 169" fill="#333333" xmlns="http://www.w3.org/2000/svg%22%3E">
                    <path d="M0 0L62.7917 17.1047C125.583 33.9531 251.167 68.5469 376.75 71.75C502.333 74.9531 627.917 48.0469 753.5 41C879.083 33.9531 1004.67 48.0469 1130.25 47.8547C1255.83 48.0469 1381.42 33.9531 1444.21 27.3547L1507 20.5V123H1444.21C1381.42 123 1255.83 123 1130.25 123C1004.67 123 879.083 123 753.5 123C627.917 123 502.333 123 376.75 123C251.167 123 125.583 123 62.7917 123H0V0Z" fill="#333333" />
                    <rect y="103" width="1507" height="395" fill="#333333" />
                </svg>


            </div>
        )
    }
}