import * as React from "react"

function SvgLogo(props) {
  return (
    <svg
      className="logo_svg__logo-svg"
      fill="inherit"
      viewBox="0 0 300 300"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      {...props}
    >
      <g transform="translate(4.658 4.658) scale(.56774)">
        <clipPath id="logo_svg__a">
          <path d="M0 0h512v512H0z" />
        </clipPath>
        <g clipPath="url(#logo_svg__a)">
          <path
            d="M303.474 0l-95.6.653L.653 207.873 0 303.475 208.526 512l95.6-.653 207.22-207.22.654-95.602L303.474 0zm-81.677 33.944l34.233-.234L57.905 231.835l-17-17L221.797 33.944zm126.919 223.173l-91.599 91.597-93.832-93.832 91.599-91.597 93.832 93.832zm-70.227-117.438l17.558-17.558 134.439 134.438-17.558 17.559-134.439-134.439zm-44.978 232.642l-17.558 17.558-134.44-134.438 17.559-17.559 134.439 134.439zM33.481 289.74l.238-34.88 182.233 182.233 156.369-156.37 17.001 17.001-173.928 173.929L33.481 289.74zm256.722 188.315l-34.233.234 198.124-198.125 17 17-180.891 180.891zM478.281 257.14L296.048 74.906l-156.369 156.37-17.001-17.001L296.606 40.347 478.519 222.26l-.238 34.88z"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g transform="translate(4.658 4.658) scale(.56774)">
        <clipPath id="logo_svg__b">
          <path d="M0 0h512v512H0z" />
        </clipPath>
        <g clipPath="url(#logo_svg__b)">
          <path
            d="M303.474 0l-95.6.653L.653 207.873 0 303.475 208.526 512l95.6-.653 207.22-207.22.654-95.602L303.474 0zm-81.677 33.944l34.233-.234L57.905 231.835l-17-17L221.797 33.944zm126.919 223.173l-91.599 91.597-93.832-93.832 91.599-91.597 93.832 93.832zm-70.227-117.438l17.558-17.558 134.439 134.438-17.558 17.559-134.439-134.439zm-44.978 232.642l-17.558 17.558-134.44-134.438 17.559-17.559 134.439 134.439zM33.481 289.74l.238-34.88 182.233 182.233 156.369-156.37 17.001 17.001-173.928 173.929L33.481 289.74zm256.722 188.315l-34.233.234 198.124-198.125 17 17-180.891 180.891zM478.281 257.14L296.048 74.906l-156.369 156.37-17.001-17.001L296.606 40.347 478.519 222.26l-.238 34.88z"
            fill="color"
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgLogo
