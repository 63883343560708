import React from "react"

import "./produtos.container.style.scss"

import Logo from "../../images/LogoHorus"

import Dashboard from "../../images/Dashboard2.png"
import App from "../../images/App2.png"
import Leituras from "../../images/Leituras2.png"
import Formulas from "../../images/Fórmulas2.png"
import Admin from "../../images/Admin2.png"

export default class ProdutosHomeContainer extends React.Component {
  cards;
  
  cardsContent = {
    "info": "",
    "title": "Produtos",
    "produtos": [
      {
        "id": "appleituras",
        "title": "App Leituras",
        "body": "Realize leituras por um aplicativo",
        "icon": "logo",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices eu libero non vulputate. Duis malesuada commodo felis, ac sodales odio auctor ut. Suspendisse consequat porttitor consequat. Praesent ipsum augue, ultricies et venenatis sed, euismod quis magna. Suspendisse potenti. Nunc non lacus nisl. Cras in ante purus. Ut et est in tellus congue eleifend. Nam auctor sapien sed turpis consequat mollis. In auctor dui neque, non iaculis erat fermentum sit amet.",
        "color": "#248BA5",
        "descriptionList": [
          "Realize leituras de sensores em campo de forma rápida e prática através de tablets ou smartphones."
        ]
      },
      {
        "id": "leituras",
        "title": "Leituras",
        "body": "Gerencie as leituras",
        "icon": "logo",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices eu libero non vulputate. Duis malesuada commodo felis, ac sodales odio auctor ut. Suspendisse consequat porttitor consequat. Praesent ipsum augue, ultricies et venenatis sed, euismod quis magna. Suspendisse potenti. Nunc non lacus nisl. Cras in ante purus. Ut et est in tellus congue eleifend. Nam auctor sapien sed turpis consequat mollis. In auctor dui neque, non iaculis erat fermentum sit amet.",
        "color": "#27ae60",
        "descriptionList": [
          "Gerencie sensores e leituras realizadas através de gráficos e planilhas, organizadas de acordo com cada estrutura da empresa.",
        ]
      },
      {
        "id": "formulas",
        "title": "Formulas",
        "body": "Faça cálculos com as leituras",
        "icon": "logo",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices eu libero non vulputate. Duis malesuada commodo felis, ac sodales odio auctor ut. Suspendisse consequat porttitor consequat. Praesent ipsum augue, ultricies et venenatis sed, euismod quis magna. Suspendisse potenti. Nunc non lacus nisl. Cras in ante purus. Ut et est in tellus congue eleifend. Nam auctor sapien sed turpis consequat mollis. In auctor dui neque, non iaculis erat fermentum sit amet.",
        "color": "#D55401",
        "descriptionList": [
          "Crie fórmulas e variáveis sobre cada sensor cadastrado, e faça cálculos com os dados de leituras de uma forma clara e objetiva."
        ]
      },
      {
        "id": "dashboard",
        "title": "Dashboard",
        "body": "Visualize dados de maneira simples e rápida",
        "icon": "logo",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices eu libero non vulputate. Duis malesuada commodo felis, ac sodales odio auctor ut. Suspendisse consequat porttitor consequat. Praesent ipsum augue, ultricies et venenatis sed, euismod quis magna. Suspendisse potenti. Nunc non lacus nisl. Cras in ante purus. Ut et est in tellus congue eleifend. Nam auctor sapien sed turpis consequat mollis. In auctor dui neque, non iaculis erat fermentum sit amet.",
        "color": "#48AE98",
        "descriptionList": [
          "Tenha à disposição todos os dados importantes de sensores, como gráficos de leituras e lista de sensores em alerta, em um dashboard personalizável"
        ]
      },
      {
        "id": "admin",
        "title": "Admin",
        "body": "Administre usuários",
        "icon": "logo",
        "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ultrices eu libero non vulputate. Duis malesuada commodo felis, ac sodales odio auctor ut. Suspendisse consequat porttitor consequat. Praesent ipsum augue, ultricies et venenatis sed, euismod quis magna. Suspendisse potenti. Nunc non lacus nisl. Cras in ante purus. Ut et est in tellus congue eleifend. Nam auctor sapien sed turpis consequat mollis. In auctor dui neque, non iaculis erat fermentum sit amet. Etiam faucibus lacinia velit, at dapibus purus facilisis feugiat. Aenean non luctus est. Nulla a faucibus lorem. Ut tincidunt elit in nisl congue tempor. Proin eget ante ut ligula maximus consequat ut a mi. Etiam at arcu dapibus, lobortis mi vitae, hendrerit purus. Proin a imperdiet ex, id commodo ante. Nunc ornare urna neque, ut feugiat ligula fringilla in. Praesent vehicula auctor augue nec dictum. Nulla porta urna vitae diam hendrerit, sed tincidunt massa ornare. Nullam posuere convallis lorem quis iaculis. Nulla interdum non arcu ac fermentum.",
        "color": "#9B59C1",
        "descriptionList": [
          "Administre as estruturas da sua empresa, como também seus usuários e as funcionalidades às quais eles têm acesso."
        ]
      }
    ]
  } 

  images = [
    {
      originalName: 'produto-leituras',
      src: Leituras
    },    {
      originalName: 'produto-appleituras',
      src: App
    },
    {
      originalName: 'produto-admin',
      src: Admin
    },
    {
      originalName: 'produto-formulas',
      src: Formulas
    },
    {
      originalName: 'produto-dashboard',
      src: Dashboard
    },

  ]

  constructor(props) {
    super(props)

    this.cardsContent.produtos.forEach(produto => {
      produto.image = this.images.find(image => {
        return image.originalName.includes(`produto-${produto.id}`)
      }).src
    })

    this.state = {
      selectedProduct: this.cardsContent.produtos[0],
      inProp: false
    }

    this.cards = this.createProducts(this.cardsContent.produtos)
  }

  createProducts = (
    cardsContent,
    selectedProduct = this.state.selectedProduct
  ) => {
    let cards = []
    let icon
    cardsContent.forEach(cardContent => {
      switch (cardContent.icon) {
        case "logo":
          icon = <Logo></Logo>
          break
        default:
          break
      }

      cards.push(
        <div
          className={`produtos__card produto-list-item produto-list-item--${cardContent.id} ${ selectedProduct.title === cardContent.title ? "produto-list-item--selected"  : "" }`}
          style={ selectedProduct.title === cardContent.title ? {fill: 'white', color: 'white', background: `linear-gradient(45deg, rgba(2,0,36,0.2) 0%, rgba(255,255,255,0.2) 100%), ${selectedProduct.color}` } : null}
          onClick={() => this.changeSelectedProduct(cardContent)}
        >
          <div className="produto-list-item__icon">{icon}</div>
          <div className="produto-list-item__title">
            <div>{cardContent.title.split(' ')[0]}</div>
            {
              cardContent.title.split(' ')[1] ?
              <div>{cardContent.title.split(' ')[1]}</div> :
              null
            }
          </div>
        </div>
      )
    })

    return cards
  }

  changeSelectedProduct(cardContent) {
    this.setState({
        selectedProduct: { ...cardContent },
        inProp: true
    })
  }
  
  render() {

    return (
      <div className="produtos-new__container" id="produtos">

        <div className="produtos-new__container-wrapper">
          <h1 className="produtos-new__titulo">{this.cardsContent.title}</h1>
  
          
          <div className="produtos-new__content-wrapper">
            <div className="produtos-new__card-holder">{this.createProducts(this.cardsContent.produtos)}</div>


              <div className="produtos-new__selected-product">

                <div className="produtos-new__selected-product-image-wrapper">
                  <div className="produtos-new__selected-product-image">
                    <img  className="meu-teste-novo" src={this.state.selectedProduct.image}></img>
                  </div>
                </div>
                <div className="produtos-new__selected-product-description">
                  <div className="produtos_new__selected-product-title" style={ { color: this.state.selectedProduct.color } }>
                    {this.state.selectedProduct.body}
                  </div>

                  <div class="content">
                    {this.state.selectedProduct.descriptionList[0]}
                  </div>

                  <a className="produtos-new__selected-product-button-wrapper" href="/modulos">
                    <button class={`produtos__selected-botao--full produtos-new__selected-product-button--${this.state.selectedProduct.id}` } style={null /* { backgroundColor: this.state.selectedProduct.color, borderColor: this.state.selectedProduct } */}>
                      Ver mais
                    </button>
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}
