import React from "react"
import "./about.container.style.scss"

export default class AboutContainer extends React.Component{
    render(){
        return(
            <div className="about">
                <div className="about-content">
                    <h1 className="about-title">
                        Horus
                    </h1>
                    <p className="about-text">
                    Tenha à disposição a praticidade e a segurança de um sistema de gerenciamento inteligente das estruturas da sua empresa, com dados armazenados em nuvem, podendo ser acessados em qualquer momento e em qualquer lugar.
                    </p>
                </div>
            </div>
        )
    }
}